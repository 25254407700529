<template>
  <v-container>
    <v-card width="500" height="auto" class="mx-auto">
      <v-form id=company >
        <v-card-text >
            <div>{{preRegCompany_cd}}{{preBusiness_no}}</div>
            <v-text-field dense outlined style="height: 45px; margin-top: 5px" 
              :loading = "loading"
              id="company_name" 
              ref="company_name" 
              label="거래처명✽" 
              v-model="company_name" 
            />
            <v-layout>
              <v-text-field dense outlined style="height: 45px; width: 180px; margin-top: 5px; margin-right: 5px" 
                id="business_no" 
                ref="business_no"
                label="사업자/법인 등록번호✽" 
                placeholder="사업자 또는 법인 등록번호의 숫자만 입력"
                v-model="business_no" 
                @keyup="getRegMask(business_no)"
                :disabled="bizNO_disabled" 

              />
              <v-text-field dense outlined style="height: 45px; margin-top: 5px" 
                id="ceo_name" 
                ref="ceo_name" 
                label="대표자명✽" 
                placeholder="대표자 또는 담당자 명"
                v-model="ceo_name" 
              />
            </v-layout>            
            <v-layout style="height:30px">
              <v-checkbox style="width:100px" color=#3a4f3f ref="enter" v-model="enter" label="입식처" ></v-checkbox>
              <v-checkbox style="width:100px" color=#3a4f3f v-model="sale" label="출하처" ></v-checkbox>
              <v-checkbox style="width:100px" color=#3a4f3f v-model="slaughter" label="도축장" ></v-checkbox>
            </v-layout >
            <v-layout >
              <v-checkbox style="width:100px" color=#3a4f3f v-model="feed" label="사료" ></v-checkbox>
              <v-checkbox style="width:100px" color=#3a4f3f v-model="medi" label="약품" ></v-checkbox>
              <v-checkbox style="width:100px" color=#3a4f3f v-model="fumi" label="방역" ></v-checkbox>
              <v-checkbox style="width:100px" color=#3a4f3f v-model="etc" label="기타" ></v-checkbox>
            </v-layout>
            <v-text-field dense outlined style="height: 45px; width: 150px; margin-top: 5px" 
              id="post_no" 
              ref="post_no" 
              label="우편번호✽" 
              v-model="post_no" 
              onKeyup="this.value=this.value.replace(/[^0-9]/g,'');"
              maxlength="5"
            />
            <v-text-field dense outlined style="height: 45px; margin-top: 5px" 
              id="address_1st" 
              ref="address_1st" 
              label="기본주소✽" 
              v-model="address_1st" 
            />
            <v-text-field dense outlined style="height: 45px; margin-top: 5px" 
              id="address_last" 
              ref="address_last" 
              label="상세주소(빌딩명,동호수,상호)" 
              v-model="address_last" 
            />
            
            <v-layout style="height: 45px; margin-top: 5px">
              <v-text-field dense outlined style="height: 45px; margin-top: 0px; " 
                id="email_id" 
                ref="email_id" 
                label="이메일" 
                v-model="email_id" 
              />
              @
              <v-combobox dense outlined style="height: 45px; margin-top: 12px margin-right:50px" 
                id="email_host" 
                ref="email_host" 
                :items="['gmail.com','hotmail.com','naver.com','daum.net',]"
                placeholder="항목이 없으면 직접 입력하세요"
                v-model="email_host" 
              />
            </v-layout>
            
            <v-text-field dense outlined style="height: 45px; width: 200px; margin-top: 5px" 
              id="phone" 
              ref="phone" 
              label="전화번호✽" 
              placeholder="숫자만 입력"
              v-model="phone"
              @keyup="getPhoneMask(phone)"
            />
            <v-layout>
              <v-layout style="height: 45px; width: 200px; margin-top: 5px">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense outlined
                      id="dealdate" 
                      ref="dealdate" 
                      v-model="dealdate"
                      label="거래시작일"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title locale="ko-KR"
                    v-model="dealdate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-layout>
        
              <v-layout style="height: 50px; width: 200px; margin-top: 5px; margin-left: 3px">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense outlined clearable
                      id="deldate" 
                      ref="deldate" 
                      v-model="deldate"
                      label="거래중지일"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title locale="ko-KR"
                    v-model="deldate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-layout>
            </v-layout>
      
      <!-- ========================================== -->
            <v-textarea outlined dense rows="2" name="input-7-4"
              label="비고"
              id="history" 
              ref="history" 
              v-model="history"
              value=""
              persistent-hint :hint="hint"
            ></v-textarea>
        </v-card-text>

        <v-card-actions class="d-block">
          <v-row justify= "center">
            <v-btn class="rounded-pill elevation-7 normal-btn mr-2 mb-5" 
              :loading= "loadingSave"
              id="save" 
              ref="save" 
              style="height: 35px; width: 100px;" 
              type="submit" 
              @click.prevent="save()">
              저장
            </v-btn>
            <v-btn class="rounded-pill elevation-7 new-btn mb-5 mr-2" 
              id="newRegister" 
              ref="newRegister" 
              style="height: 35px; width: 100px;" 
              @click="newRegister()">
              새로등록
            </v-btn>
            <v-btn class="rounded-pill elevation-7 dialog-btn mb-5 mr-2"  
              id="listVue" 
              ref="listVue" 
              style="height: 35px; width: 100px;" 
              @click="listVue()">
              목록
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import Common from "@/utils/custom/common.js";

export default {
  name: 'DealCompany',

  data: () => ({
    bizNO_disabled: true,
    preRegCompany_cd: "",
    preBusiness_no: "",
    
    company_name: "",
    ceo_name: "",
    business_no: "",
    post_no: "",
    enter: false,
    sale: false,
    slaughter: false,
    feed: false,
    medi: false,
    fumi: false,
    etc: false,
    address_1st: "",
    address_last: "",
    email_id: "",
    email_host: "",
    phone: "",
    dealdate: "",
    deldate: "",
    history: "",
    hint: "",
    
    // businessDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu1: false,
    menu2: false,

    loading: false,
    loadingSave: false,

    // phone:null,

  }),
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    
    // console.log("params",this.$route.params,this.isNull(this.$route.params));

    if (!this.isNull(this.$route.params)) {
      // console.log("경영체코드 : ", this.$route.params.business_no);
      this.infoDetail(this.$route.params);
    }
  },

  methods: {
    // ...mapMutations(['doLogin']),
    clearData() {
      this.bizNO_disabled = true;
      this.preRegCompany_cd = "",
      this.preBusiness_no = "";
      this.business_no = "";
      this.company_name = "";
      this.ceo_name = "";
      this.enter = false,
      this.sale = false,
      this.slaughter = false,
      this.feed = false,
      this.medi = false,
      this.fumi = false,
      this.etc = false,
      this.post_no = "";
      this.address_1st = "";
      this.address_last = "";
      this.email_id = "";
      this.email_host = "";
      this.phone = "";
      this.dealdate = "";
      this.deldate = "";
      this.history = "";
      this.hint = "";
    },
    checkData() {
      if (!this.company_name.trim()) {
        this.$refs.company_name.focus();
        return "거래처명을 입력하세요";
      }
      if (!this.business_no) {
        this.$refs.business_no.focus();
        return "사업자/법인 등록번호를 입력하세요";
      }
      if (!(Common.checkBizNo(this.business_no)) && !(Common.checkRegNo(this.business_no))) {
        this.$refs.business_no.focus();
        return "사업자/법인 등록번호가 틀렸습니다.";
      }  
      if (!this.enter && !this.sale && !this.slaughter && !this.feed && !this.medi && !this.fumi && !this.etc ) {
        this.$refs.business_no.focus();
        return "거래유형중 하나 이상을 선택하세요";
      }
      if (!this.post_no) {
        this.$refs.post_no.focus();
        return "우편번호를 입력하세요";
      }
      if (!this.address_1st.trim()) {
        this.$refs.address_1st.focus();
        return "기본주소를 입력하세요";
      }
      if (!this.phone.trim()) {
        this.$refs.phone.focus();
        return "전화번호를 입력하세요";
      }
      if (!this.dealdate) {
        this.$refs.dealdate.focus();
        return "거래시작일을 입력하세요";
      }
      return "";
    },
    save() {
      // console.log("checkdata", this.checkData());
      this.$store.commit("resMessage","");

      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;
      if (this.preBusiness_no) {
        // console.log("업데이트");
        Apis.updateDealCompany({
          regcompany_cd: this.preRegCompany_cd,
          business_no: this.preBusiness_no,
          company_name: this.company_name.trim(),
          ceo_name: this.ceo_name.trim(),
          enter: this.enter ?  "Y" : "",
          sale: this.sale ?  "Y" : "",
          slaughter: this.slaughter ?  "Y" : "",
          feed: this.feed ?  "Y" : "",
          medi: this.medi ?  "Y" : "",
          fumi: this.fumi ?  "Y" : "",
          etc: this.etc ?  "Y" : "",
          post_no: this.post_no,
          address_1st: this.address_1st.trim(),
          address_last: this.address_last.trim(),
          email1: this.email_id.trim() + "@" + this.email_host, 
          email2: "",
          phone1: this.phone.replace(/[^0-9]/g, ''),
          phone2: "",
          dealdate: this.dealdate,
          deldate: this.deldate,
          history: this.history.trim(),
          etc1: "",
          etc2: "",
          etc3: "", 
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              // console.log("selOtion=>",this.$route.params.selOption);
              this.$router.push({
                        name: "ListDealCompanies",
                        params: { business_no: this.business_no, selOption: this.$route.params.selOption}
              });
              this.clearData();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류")
              alert(err);
              // console.log(err)
          }
        )
      } else {
        // console.log("인서트",this.email_host.trim());
        Apis.insertDealCompany({
          company_name: this.company_name.trim(),
          ceo_name: this.ceo_name.trim(),
          business_no: this.business_no.replace(/[^0-9]/g, ''),
          enter: this.enter ?  "Y" : "",
          sale: this.sale ?  "Y" : "",
          slaughter: this.slaughter ?  "Y" : "",
          feed: this.feed ?  "Y" : "",
          medi: this.medi ?  "Y" : "",
          fumi: this.fumi ?  "Y" : "",
          etc: this.etc ?  "Y" : "",
          post_no: this.post_no,
          address_1st: this.address_1st.trim(),
          address_last: this.address_last.trim(),
          email1: this.email_id.trim() + "@" + this.email_host, 
          email2: "",
          phone1: this.phone.replace(/[^0-9]/g, ''),
          phone2: "",
          dealdate: this.dealdate,
          deldate: this.deldate,
          history: this.history.trim(),
          etc1: "",
          etc2: "",
          etc3: "", 
          },(res) => {  
            if (res.result) {
              // 정상 등록
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.clearData();
            } else if (res.resultCode == "E124") {
                // 등록번호 중복
                if (confirm(res.message + "기 등록된 사업자에 거래유형을 추가 하시렵니까?")) {
                  this.infoDetail({company_cd: res.data.regcompany_cd, business_no: res.data.business_no});
                  this.loadingSave = false;
                  return;
                } else {
                  this.loadingSave = false;
                  return;
                }
            } else
              this.loadingSave = false;
              alert(res.message);
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류")
              alert(err);
              // console.log(err)
          }
        )
      }
    },
    infoDetail(code) {
      // console.log("bizno",code);
      this.$store.commit("resMessage","");
      this.loading = true;
      this.clearData();
      Apis.infoDealCompany({
        company_cd: code.company_cd, 
        business_no: code.business_no, 
        },(res) => {  
          if (res.result) {
            this.preRegCompany_cd = code.company_cd;
            this.preBusiness_no = code.business_no;
            this.company_name = res.data.company_name;
            this.ceo_name = res.data.ceo_name;
            this.enter = res.data.enter && res.data.enter == "Y" ?  true : false;
            this.sale = res.data.sale && res.data.sale == "Y" ?  true : false;
            this.slaughter = res.data.slaughter && res.data.slaughter == "Y" ?  true : false;
            this.feed = res.data.feed && res.data.feed == "Y" ?  true : false;
            this.medi = res.data.medi && res.data.medi == "Y" ?  true : false;
            this.fumi = res.data.fumi && res.data.fumi == "Y" ?  true : false;
            this.etc = res.data.etc && res.data.etc == "Y" ?  true : false;
            this.business_no = Common.getRegMask(res.data.business_no);
            this.post_no = res.data.post_no;
            this.address_1st = res.data.address_1st;
            this.address_last = res.data.address_last;
            // let email = res.data.email1.split("@",2);
            // this.email_id = email[0];
            // this.email_host = email[1];
            this.phone = Common.getMask(res.data.phone1);
            this.enter = res.data.enter == "Y" ? true : false;
            this.dealdate = res.data.dealdate;
            this.deldate = res.data.deldate;
            this.history = res.data.history;
            this.hint = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.loading = false;
            
            this.$store.commit("resMessage",res.message);
          } else {
            this.loading = false;
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류")
            this.loading = false;
            alert(err);
            // console.log(err)
        }
      ) 
    },
    newRegister() {
      this.clearData();
      this.bizNO_disabled = false;
      this.$refs.company_name.focus();
      this.$store.commit("resMessage","새로 등록 준비가 되었습니다.");

    },
    listVue() {
      this.$router.push("/farminfo/listdealcompanies")
    },
    getPhoneMask(val) {
      this.phone = Common.getMask(val)
    },
    getRegMask(val) {
      // let res = Common.getRegMask(val)
      this.business_no = Common.getRegMask(val)
    },
    // searchAddress() { TODO 주소검색 API

    // },

  }  // -----------methods 

}
</script>

<style lang="scss" scoped>
.normal-btn{
  vertical-align: middle !important;
  color:white ;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>
